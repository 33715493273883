import * as React from 'react';
import Head from 'next/head';

import {
  Lang,

  useDimensions,

  Footer,
} from '@bluecurrent/web-component-lib';

import Url from '../config/Links.config';
import Setup from '../config/setup';

import Header from './Sections/Header';

export default function Layout(props) {
  const size = useDimensions();
  const { _, i18n } = Lang.useTranslation();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  const title = 'Installer';

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0" />
        <title>
          {`${title} • Blue Current`}
        </title>
      </Head>
      <div
        style={{
          overflow: 'hidden',
        }}
      >
        <Header />
        <main
          style={{
            marginTop: size.width < 1440 ? 80 : 190,
            marginBottom: 75,
          }}
        >
          {
            props.children
          }
        </main>
        <Footer
          text={_('slogan', { ns: 'branding' })}
          contact
          subsidy
          links={[
            {
              id: 0,
              text: _('faq', { ns: 'ui' }),
              href: Url(lang).FAQ,
              target: '_blank',
            },
            {
              id: 1,
              text: _('workAt', { ns: 'ui' }),
              href: Url().CAREERS,
              target: '_blank',
            },
            {
              id: 2,
              text: _('invest.invest', { ns: 'branding' }),
              href: Url().BLUE_CURRENT_INVEST,
              target: '_blank',
            },
          ]}
          subItems={[
            {
              id: 0,
              text: _('privacyPolicy', { ns: 'legal' }),
              href: Url().PRIVACY_POLICY,
              target: '_self',
            },
            {
              id: 1,
              text: _('termsConditions', { ns: 'legal' }),
              href: Url().TERMS_AND_CONDITIONS,
              target: '_self',
            },
          ]}
        />
      </div>
    </>
  );
}
