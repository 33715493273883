import Setup from './setup';

export default function Url(language) {
  const fallbackLanguageHandler = () => (language === undefined ? Setup.FALLBACK_LANG : language);

  return {
    LINKEDIN: 'https://www.linkedin.com/company/blue-current-b-v-/?originalSubdomain=nl',
    INSTAGRAM: 'https://www.instagram.com/bluecurrentbv/',
    GOOGLE_MAPS: 'https://www.google.com/maps?q=blue+current&rlz=1C1ONGR_nlNL932NL932&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjOndW-x-fxAhUNzaQKHTq0DyUQ_AUoA3oECAEQBQ',

    BLUE_CURRENT: 'https://www.bluecurrent.nl',
    BLUE_CURRENT_APP: 'https://www.bluecurrent.nl/products/software#app',
    BLUE_CURRENT_MY: 'https://my.bluecurrent.nl/',
    BLUE_CURRENT_INVEST: 'https://invest.bluecurrent.nl',

    FAQ: `https://help.bluecurrent.nl/${fallbackLanguageHandler() === 'en' ? '' : `${fallbackLanguageHandler()}/`}knowledge`,
    CAREERS: 'https://werkenbijbluecurrent.nl',

    REQUEST_CHARGE_CARD: 'https://bo.bluecurrent.nl/app/f?p=101:110:::NO:RP,110',
    REQUEST_CHARGE_POINT_BUSINESS: '/forms/request-charge-points-for-businesses',
    REQUEST_CHARGE_POINT_AT_HOME: '/forms/request-charge-point-for-at-home',
    REQUEST_FREE_EXPLOITATION_CHARGE_POINT: '/forms/request-free-exploitation-charge-points',

    NEWS: '/news',
    PRIVACY_POLICY: '/legal/privacy-policy',
    TERMS_AND_CONDITIONS: '/legal/terms-and-conditions',
  };
}
