import Storage from '../Storage';

const detectLanguage = (i18n) => {
  if (Storage.getItem('lang')) {
    // i18n.changeLanguage(Storage.getItem('lang'));
    i18n.changeLanguage('nl');
  } else {
    i18n.changeLanguage('nl');
  }
};

export default detectLanguage;
